// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

// This is the keep the toggle switch checked when clicked. The toggle switch is not a real toggle switch, but to show that OIDC is enabled.
on('click', '.js-business-oidc-configuration-status', element => {
  const status = element.currentTarget
  const button = status.querySelector<HTMLElement>('button')!
  status.className += ' ToggleSwitch--checked'
  button.setAttribute('aria-pressed', 'true')
})

// This is to allow the OIDC toggle switch to activate the confirmation dialog
observe('.js-business-oidc-configuration-status button', element => {
  const dialogId = 'oidc-configuration-status'
  element.setAttribute('id', `dialog-show-${dialogId}`)
  element.setAttribute('data-show-dialog-id', dialogId)
})
